<template>
  <div style="padding: 10px;">
    <div class="app-container">
      <el-card>
        <el-row type="flex">
          <el-button @click="$router.back()">返回</el-button>
          <el-button v-print="printObj" id="trueBtn" size="small" type="primary" style="display: none">出货单打印</el-button>
          <el-button @click="handlePrint" size="small" type="primary">出货单打印</el-button>
          <el-button @click="print" size="small" type="success">审核</el-button>
        </el-row>
        <div id="printView" style="padding-left: 20px; padding-right: 20px;">
          <h2 style="text-align: center;margin: 30px 0 30px 0;font-family: SimSun;">出货单</h2>
          <table class="table" border="0pm" cellspacing="0" align="left" width="100%" style="font-size: 14px;font-family: SimSun;margin-bottom: 10px; table-layout:fixed;word-wrap:break-word;word-break:break-all">
<!--            <tr>-->
<!--              <td colspan="8" class="centInfo" id="myPrintOne">-->
<!--                <vue-barcode :value="barcodeValue" :options="barcodeOptions" height="56"></vue-barcode>-->
<!--              </td>-->
<!--            </tr>-->
            <tr>
              <td colspan="4" style="padding:5px;" align="left">发货方式：{{ formData.sendType | sendTypeToCnFormate }}</td>
              <td colspan="8" style="padding:5px; " align="left">单据编号：{{ barcodeValue }}</td>
            </tr>
            <tr>
              <td colspan="4" style="padding:5px; " align="left">归属部门：{{ formData.deptSortOut | deptSortOutToCnToCnFormate }}</td>
              <td colspan="4" style="padding:5px;" align="left">期望发货日期：{{ formData.hopeTime }}</td>
            </tr>
            <tr>
              <td colspan="4" style="padding:5px;" align="left">制单人：{{ createdName }}</td>
              <td colspan="4" style="padding:5px;" align="left">制单时间：{{ createdTime|dateFormate }}</td>
            </tr>
            <tr>
              <td colspan="4" style="padding:5px;" align="left">审核人：{{ printName }}</td>
              <td colspan="4" style="padding:5px;" align="left">审核时间：{{ printTime|dateFormate }}</td>
            </tr>
            <tr>
              <td colspan="3" style="padding:5px;" align="left">客户：{{ formData.recipient }}</td>
              <td colspan="3" style="padding:5px;" align="left">客户电话：{{ formData.recipientPhone }}</td>
              <td colspan="6" style="padding:5px;" align="left">客户地址：{{ formData.recipientAddress }}</td>
            </tr>

            <tr>
              <td colspan="3" style="padding:5px;" align="left">发货人：{{ formData.sender }}</td>
              <td colspan="3" style="padding:5px;" align="left">发货电话：{{ formData.senderPhone }}</td>
              <td colspan="6" style="padding:5px;" align="left">签收人及日期：</td>
            </tr>
          </table>
          <div style="font-size: 16px;font-family: SimSun;font-weight: bold;margin: 10px 5px 10px 5px;">商品明细</div>
          <table class="yk-table" border="1pm" cellspacing="0" align="center" width="92%" style="font-size: 14px;font-family: SimSun;margin-bottom: 10px;">
            <tr>
              <th width="55px">序号</th>
              <th>产品</th>
              <th>颜色</th>
              <th>规格</th>
              <th>发货数量</th>
            </tr>
            <tr v-for="(item, index) in tableDataList" :key="index" align="center">
              <td>{{ index + 1 }}</td>
              <td>{{ item.goodsName }}</td>
              <td>{{ item.colourName }}</td>
              <td>{{ item.specsName }}</td>
              <td>{{ item.sendCount }}</td>
            </tr>
            <tr align="center">
              <td colspan="4">合计</td>
              <td>{{ adAmount }}</td>
            </tr>
          </table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import {sendTypeToCn,deptSortOutToCn,orderStatusToCn} from '@/utils/enums'
import VueBarcode from 'vue-barcode';
import {getUserInfo} from '@/services/user'
import {printOrderInfo, getOrderGoodsList,orderTackList} from '@/services/order'
import {dateFormate} from '@/utils/dateUtil'

export default {
  // components: {
  //   VueBarcode
  // },
  data() {
    return {
      printObj: {
        id: 'printView'
      },
      formData: {},
      printDate: '',
      barcodeValue: '', // 设置条形码的值
      barcodeOptions: {
        format: 'code39', // 条形码格式，可以根据需要更改
        displayValue: true, // 是否显示条形码的值
      },
      tableDataList: [],
      userInfo:{},
      adAmount:0,
      createdName:'',
      createdTime:'',
      printName:'',
      printTime:'',
    }
  },
  // 创建完毕状态
  created() {
    this.loadUserInfo()
    this.formData = this.$route.query
    this.barcodeValue = this.formData.orderNo
    this.initGoodsList(this.formData.orderNo)
    this.loadAllOrderTackList(this.formData.orderNo)
  },
  // 组件更新
  methods: {
    async loadAllOrderTackList(orderNo) {
      // 开始加载数据
      this.isLoading = true
      const params = {
        orderNo
      }
      const {data} = await orderTackList(params)
      if (200 === data.code) {
        let tackList = data.data
        tackList.forEach(tackData=>{
          if ("11"==tackData.orderStatus) {
            this.createdName=tackData.tackName
            this.createdTime=tackData.tackTime
          }
          if ("31"==tackData.orderStatus) {
            this.printName=tackData.tackName
            this.printTime=tackData.tackTime
          }
        })
      } else {
        this.$message.error("加载数据失败")
      }
      this.isLoading = false
    },
    // 商品列表
    async initGoodsList(orderNo) {
      const {data} = await getOrderGoodsList(orderNo)
      if (200 === data.code) {
        this.tableDataList = data.data
        let sum = 0
        this.tableDataList.forEach(x => { sum += x.sendCount })
        this.adAmount = sum
      } else {
        this.$message.error('获取商品列表失败')
      }
    },
    handlePrint() {
      // 在这里进行逻辑判断
      if (this.printName) {
        // 如果满足条件，则调用打印功能
        this.printDocument();
      } else {
        // 如果不满足条件，可以给出提示或进行其他操作
        this.$message.error('未审核不能打印');
      }
    },
    printDocument() {
      // 实现打印逻辑的代码
      document.getElementById("trueBtn").click();
    },
    async loadUserInfo()
      {
        const {data} = await getUserInfo()
        if (data.code === 200) {
          return this.userInfo = data.data
        }
        this.$message.error('获取用户信息失败')
      }
    ,
      async  print()
      {
        try {
          await this.$confirm('此操作将订单改为已审核状态, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          const params = {
            orderNo: this.formData.orderNo,
            tackName: this.userInfo.userName,
            tackPhone: this.userInfo.phone
          }
          const {data} = await printOrderInfo(params)
          if (200 === data.code) {
            this.$message.success("操作成功")
          } else {
            return this.$message.warning(data.message)
          }
          await this.loadAllOrderTackList(this.formData.orderNo)
        } catch (err) {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }
      }
    },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      return dateFormate(date)
    },
    orderStatusToCnFormate(code) {
      return orderStatusToCn(code)
    },
    sendTypeToCnFormate(code) {
      return sendTypeToCn(code)
    },
    deptSortOutToCnToCnFormate(code) {
      return deptSortOutToCn(code)
    },
  }
  }
</script>

<style media="print">
  @page {
    size: auto;
    margin: 3mm;
  }
  html {
    background-color: #ffffff;
    margin: 0px;
  }
  body {
    border: solid 1px #ffffff;
  }
</style>
<style lang="scss">
@media print {
  #printView {
    display: block;
    width: 100%;
    overflow: hidden;
  }
}
</style>

