import request from '@/utils/request'

// 分页&多条件查询
export function findAllOrderInfoByPage(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/findAllOrderInfoByPage`,
    data
  })
}

// 分页&多条件查询-未装车
export function findAllOrderInfoByPage2(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/findAllOrderInfoByPage2`,
    data
  })
}
// 拣货商品列表
export function getOrderGoodsList(data) {
  return request({
    method: 'get',
    url: `/auth/orderInfo/findGoods/${data}`,
    data
  })
}

// 保存线下订单信息
export function saveOrderInfo(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/saveOrderInfo`,
    data
  })
}

// 线下订单撕单
export function delOrderInfo(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/del`,
    data
  })
}

// 推送打单
export function pushOrderInfo(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/push`,
    data
  })
}

// 线下订单拣货
export function orderPacking(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/packing`,
    data
  })
}

// 线下已装车确认
export function orderLoading(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/loading`,
    data
  })
}

// 已审核确认
export function printOrderInfo(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/print`,
    data
  })
}

// 已送达确认
export function orderArriving(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/arriving`,
    data
  })
}

// 协商送回
export function orderConsulting(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/consulting`,
    data
  })
}

// 已送回确认
export function orderSendBacked(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/sendBacked`,
    data
  })
}

// 打单记录列表
export function orderTackList(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/tackList`,
    data
  })
}

// 更新订单商品状态
export function updateOrderGoodsStatus(data) {
  return request({
    method: 'post',
    url: `/auth/orderInfo/updateOrderGoodsStatus`,
    data
  })
}
